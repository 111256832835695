<template>
  <div class="card bg-base-100 rounded-md border-2 border-gray-100">
    <div class="card-body">
      <h2 class="card-title flex justify-between text-gray-700 mb-6">
        {{ plan.displayName }}
      </h2>
      <ul class=" list-disc list-inside text-gray-600 text-sm h-56">
        <li v-for="feature in plan.features" :key="feature" class="mb-1">
          {{ feature }}
        </li>
      </ul>
      <div class="border-t border-t-gray-200 mt-2 mb-2"></div>
      <div v-if="annual" class="flex gap-1 justify-center items-center align-middle text-primary mb-2">
        <span>{{ plan.currency }}</span>
        <span class="font-bold text-xl">{{ plan.annualPrice }}</span>
        <span> / year</span>
        <!-- <span v-if="annual && plan.annualPrice > 0" class="text-xs">(paid annually)</span> -->
      </div>
      <div v-else class="flex gap-1 justify-center items-center align-middle text-primary mb-2">
        <span>{{ plan.currency }}</span>
        <span class="font-bold text-xl">{{ annual ? plan.annualPrice / 12 : plan.monthlyPrice }}</span>
        <span> / month</span>
        <span v-if="annual && plan.annualPrice > 0" class="text-xs">(paid annually)</span>
      </div>
      <SubscribeButton
        v-if="!isSelectedPlanCurrent"
        :planName="planName"
        :account="account"
        :useMpesa="useMpesa"
        @transaction="$emit('transaction', $event)"
      >
        Choose plan
      </SubscribeButton>
      <div v-else class="text-center text-xs text-gray-500 font-bold">
        Current plan
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import SubscribeButton from './SubscribeButton.vue';
import type { AccountWithSubscription, TransactionWithSubcription } from "@quickbyte/common";
import { ref, computed } from 'vue';
import { FREE_TIER } from "./plans";
import type { PlanDetails } from "./types";

const props = defineProps<{
  plan: PlanDetails;
  /**
   * Whether to show annual plans, otherwise monthly plans will be shown
   */
  annual: boolean;
  account: AccountWithSubscription,
  useMpesa?: boolean;
}>();

defineEmits<{
  (e: 'transaction', transaction: TransactionWithSubcription): void;
}>();

const currentPlan = computed(() => props.account?.subscription?.plan.name || FREE_TIER);
const isSelectedPlanCurrent = computed(() => props.annual ? currentPlan.value === props.plan.annualPlanName : currentPlan.value === props.plan.monthlyPlanName);
const planName = computed<string>(() => props.annual ? props.plan.annualPlanName : props.plan.monthlyPlanName);
</script>
<template>
  <div class="flex w-full overflow-y-auto">
    <div class="border-r border-r-gray-800 w-72">
      <div v-for="item in menuItems" :key="item.name">
        <div class="px-4 py-4 flex flex-col gap-2 text-gray-500 border-b border-gray-700 shadow-sm drop-shadow-md ">
          <div class="uppercase">
            {{ item.name }}
          </div>
          <div class="text-xs" v-if="item.subTitle">
            {{ item.subTitle }}
          </div>
          <div class="text-xs" v-if="item.subSubTitle">
            {{ item.subSubTitle }}
          </div>
        </div>
        <router-link
          v-for="subItem in item.subMenu" :key="subItem.name"
          class="px-4 py-2 border-b border-gray-700 flex items-center gap-2 text-gray-400 hover:text-gray-200"
          activeClass="text-gray-200 font-bold"
          exactActiveClass="text-gray-200 font-bold"
          :to="subItem.link"
        >
          <Icon :icon="subItem.icon"/> {{ subItem.name }}
        </router-link>
      </div>
      
    </div>
    <div class="px-5 py-5 bg-white flex-1 text-gray-800 overflow-y-auto overflow-x-auto">
      <router-view></router-view>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, watch } from "vue";
import { store } from "@/app-utils";
import { ensure } from "@/core";
import { useRoute } from "vue-router";
import { Icon } from "@iconify/vue";

type MenuItem = {
  name: string;
  subTitle?: string;
  subSubTitle?: string;
  subMenu: {
    name: string;
    link: { name: string, params: Record<string, any> };
    icon: string;
  }[];
}

const route = useRoute();

const menuItems = computed(() => {
  const account = ensure(store.currentAccount.value);
  const user = ensure(store.user.value);
  const result: MenuItem[] = [];
  if (account.owner._id === user._id) {
    result.push({
      name: 'Account',
      subTitle: `Manage ${account.name}`,
      subSubTitle: `Account ID: ${account._id}`,
      subMenu: [
        {
          name: 'Account Usage',
          link: { name: 'account-usage', params: { accountId: account._id } },
          icon: 'lucide:gauge'
        },
        {
          name: 'Billing',
          link: { name: 'billing', params: { accountId: account._id } },
          icon: "lucide:credit-card"
        }
      ]
    })
  }
  // result.push(
  //   {
  //     name: 'Personal',
  //     subMenu: [
  //       {
  //         name: 'Profile',
  //         link: { name: 'billing', params: { accountId: account._id } }
  //       }
  //     ]
  //   }
  // );
  
  return result;
});

watch(() => route.path, () => {
  const user = ensure(store.user.value, "Expected user to be available in settings");
  const ownedAccount = ensure(store.accounts.value.find(a => a.owner._id === user._id), 'Expected user to have owned account.');
  store.setCurrentAccount(ownedAccount._id);
});

</script>
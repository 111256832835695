<template>
  <AppShell>
    <router-view v-if="dataLoaded"></router-view>
    <div v-else-if="loading" class="mt-10 w-full flex items-center justify-center">
      <span class="loading loading-spinner" />
    </div>
    <div v-else-if="error" class="mt-10 text-center text-error">
      Error: {{ error }}
    </div>
    <InvitesPromptWatcher />
    <SubscriptionPlansDialog
      ref="paymentsDialog"
      v-if="account"
      :account="account"
      :defaultMonthly="true"
    />
  </AppShell>
</template>
<script lang="ts" setup>
import { store, initUserData, showToast, logger } from "@/app-utils";
import AppShell from '@/components/AppShell.vue';
import { SubscriptionPlansDialog } from "@/components/subscriptions";
import InvitesPromptWatcher from '@/components/InvitesPromptWatcher.vue';
import { computed, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";

const loading = ref(false);
const dataLoaded = ref(false);
const error = ref<string>();
const router = useRouter();
const account = computed(() => store.ownedAccount.value);
// Prompt user to pay if they have no account with an active subscription
// To avoid disruption, this only appears once per session or reload
const showPaymentDialog = computed(() => !store.accounts.value.some(a => a.subscription && ['active', 'pending'].includes(a.subscription.status)));
const paymentsDialog = ref<typeof SubscriptionPlansDialog>();

watch([showPaymentDialog, paymentsDialog], () => {
  if (showPaymentDialog.value && account.value) {
    paymentsDialog.value?.open();
  }
});

onMounted(async () => {
  loading.value = true;
  try {
    await initUserData(router);
    dataLoaded.value = true;
  } catch (e: any) {
    showToast(e.message, 'error');
    logger.error(e, e.message);
    error.value = e.message;
  } finally {
    loading.value = false;
  }
  
  dataLoaded.value = true;
});
</script>
<template>
  <div class="flex flex-col w-full min-h-screen text-[#9499ae] text-sm">
    <NavBarBase class="z-10 opacity-100 bg-black">
      <template #logo>
        <Logo />
      </template>
      <template #right>
        
      </template>
    </NavBarBase>
    <div class="flex flex-col mx-auto w-full px-5 sm:w-[700px] gap-5 flex-1 min-h-screen overflow-y-auto">
      <UiLayout class=" text-5xl font-bold text-gray-200 mt-10 mb-4 sm:mb-10" alignCenter>
        Faster reliable file transfers for large files
      </UiLayout>
      <UiLayout class="text-sm gap-2 flex-col mb-5">
        <ul class="flex flex-col gap-1 text-sm">
          <li>Free to download and install</li>
          <li>Upload files directly to your project</li>
          <li>Download files from shared links</li>
          <li>No account required for shared links download</li>
          <li>Improved transfer performance</li>
          <li>Automatically resume incompleted transfers after relaunch</li>
        </ul>
      </UiLayout>
      <UiLayout class="gap-2">
        <UiLinkButton
          v-for="platform in platforms"
          :key="platform.name" 
          primary
          :to='platform.downloadUrl'
        >
          {{ platform.name }}
        </UiLinkButton>
      </UiLayout>
      <UiLayout>
        <img src="/img/macos-transfer-home.png" class="w-full" />
      </UiLayout>
    </div>
</div>
</template>
<script lang="ts" setup>
import { computed } from "vue";
import  { UiLayout, UiButton, UiLinkButton } from "@/components/ui";
import AppShell from '@/components/AppShell.vue'
import NavBarBase from '@/components/NavBarBase.vue';
import Logo from '@/components/Logo.vue';
import { useDesktopTransferAppInfoQuery } from "@/app-utils";

const appInfoQuery = useDesktopTransferAppInfoQuery();
const version = computed(() => appInfoQuery.data.value?.version);
const platforms = computed(() => appInfoQuery.data.value?.platforms || []);
</script>
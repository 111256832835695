<template>
  <div class="flex flex-1 flex-col items-center overflow-y-auto">
    <div class="flex justify-center mb-2">
      <div class="text-lg flex gap-2 items-center">
        <span>Monthly</span>
        <input type="checkbox" v-model="isAnnual" class="toggle toggle-xs toggle-primary" checked />
        <span>Annually <span class="text-xs">(20% off)</span></span>
      </div>
    </div>
    <div class="mb-2">
      <div class="text-xs flex gap-2 items-center">
        <span class="text-gray-500" :class="{ 'text-gray-900': useMpesa }">Enable M-PESA</span>
        <UiCheckbox v-model:checked="useMpesa" />
      </div>
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2 overflow-y-auto">
      <!-- <div  v-for="plan in plans" :key="plan.displayName" class=""> -->
        <PlanCard
          v-for="plan in plans"
          :key="plan.displayName"
          :plan="plan"
          :annual="isAnnual"
          :account="account"
          :useMpesa="useMpesa"
          @transaction="$emit('transaction', $event)"
        />
      <!-- </div> -->
    </div>
    <div class="mt-2 text-xs">
      Need more? Reach out to us at <a href="mailto:support@quickbyte.io" class="underline">support@quickbyte.io</a>.
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import type { AccountWithSubscription, TransactionWithSubcription } from "@quickbyte/common";
import { plans } from "./plans";
import { UiCheckbox } from "@/components/ui";
import PlanCard from "./PlanCard.vue";

const props = defineProps<{
  account: AccountWithSubscription,
  /**
   * Whether to show the monthly plans by default. Defaults to `false`.
   */
  defaultMonthly?: boolean;
}>();

defineEmits<{
  (e: 'transaction', transaction: TransactionWithSubcription): void;
}>();

const isAnnual = ref(!props.defaultMonthly);
const useMpesa = ref(false);
</script>
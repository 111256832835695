import { useQuery } from '@tanstack/vue-query'
import { trpcClient } from '../api';
import type { MaybeRef } from 'vue';
import { unref } from "vue";

export function getProjectTransferQueryKey(
    projectId: MaybeRef<string>,
    transferId: MaybeRef<string>) {
    return ['projectTransfers', projectId, transferId];
}

export function useProjectTransferQuery(
    projectId: MaybeRef<string>,
    transferId: MaybeRef<string>
) {
    const queryKey = getProjectTransferQueryKey(projectId, transferId);
    const result = useQuery({
        queryKey,
        queryFn: () => trpcClient.getProjectTransfer.query({
            projectId: unref(projectId),
            transferId: unref(transferId)
        }),
    });

    return result;
}
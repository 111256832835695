<template>
  <UiButton primary @click="pay()" :loading="loading">
    <slot></slot>
  </UiButton>
</template>
<script lang="ts" setup>
import { ref } from 'vue';
import type { AccountWithSubscription, TransactionWithSubcription } from '@quickbyte/common';
import { store } from '@/app-utils';
import { ensure } from '@/core';
import { UiButton } from '@/components/ui';
import { payWithDefault } from "./subscription-helpers";

// TODO: we hardcode this for now because
// we only have a few plans at the moment.
type PlanName = string;

const props = defineProps<{
  planName: PlanName,
  account: AccountWithSubscription;
  useMpesa?: boolean;
}>();

const emit = defineEmits<{
  (e: 'transaction', transaction: TransactionWithSubcription): void;
}>();

const loading = ref(false);

async function pay() {
  // const account = ensure(store.currentAccount.value);
  const user = ensure(store.user.value, "Expected user to be set");
  await payWithDefault({
    user,
    account: props.account,
    planName: props.planName,
    loadingTracker: loading,
    useMpesa: props.useMpesa,
    onTransaction: (tx) => emit('transaction', tx)
  });
}
</script>
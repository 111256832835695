<template>
  <div class="p-5 w-full sm:w-[700px] flex flex-col items-center justify-center mx-auto">
    <template v-if="loading">
      Loading...
    </template>
    <div v-else-if="transfer" class="flex flex-col gap-5 w-full">
      <div class="text-lg">
        <template v-if="transfer.targetMedia">
          New versions uploaded to
          <router-link
            class="underline text-gray-300"
            :to="{
              name: 'player',
              params: {
                projectId: transfer.projectId,
                mediaId: transfer.mediaId
              }
            }">{{ transfer.targetMedia.name }}</router-link>
        </template>
        <template v-else>
          New media assets uploaded to project
        </template>
      </div>
      <div>
        <div class="text-sm font-bold mb-3">
          Overview
        </div>
        <div class="text-xs flex flex-col gap-1 rounded-md border-2 border-gray-700 px-4 py-2">
          <div>
            Upload started {{ formatDateTime(transfer._createdAt) }} by <span class="text-gray-300">{{ transfer.uploader.name }}</span>
          </div>
          <div v-if="transfer.transferCompletedAt">
            Upload completed at {{ formatDateTime(transfer.transferCompletedAt) }}
          </div>
          <div>
            Number of files: {{ transfer.numFiles }}
          </div>
          <div>
            Total size: {{ humanizeSize(transfer.totalSize) }}
          </div>
          <div>
            Status: {{ transfer.status }}
          </div>
          <div v-if="transfer.path?.length" class="flex gap-2">
            Uploaded to
            <FolderPathBreadcrumbs
              :path="transfer.path"
              :getFolderLink="getFolderLink"
              :maxPathItems="3"
            />
          </div>
        </div>
      </div>
      <div v-if="transfer.targetMedia && filesToVersions" class="flex flex-col gap-2">
        <div class="font-bold mb-1">
          Uploaded versions
        </div>
        <div
          v-for="file in transfer.files"
          :key="file._id"
          class="rounded-md border-2 border-gray-700 px-4 py-2 flex flex-col gap-2"
        >
          <div>
            <router-link
              class="underline text-gray-300"
              :to="{
                name: 'player',
                params: {
                  projectId: transfer.projectId,
                  mediaId: transfer.mediaId
                },
                query: {
                  version: filesToVersions[file._id]._id
                }
              }"
            >
              {{ filesToVersions[file._id].name }}
            </router-link>
          </div>
          <div class="text-xs flex flex-col gap-1">
            <div v-if="file.uploadStatus">
              Status: {{ file.uploadStatus }}
            </div>
            <div v-if="file.uploadStatus">
              Size: {{ humanizeSize(file.size) }}
            </div>
          </div>
        </div>
      </div>
      <div v-else class="flex flex-col gap-2">
        <div class="font-bold mb-1">
          Uploaded media assets
        </div>
        <div
          v-for="file in transfer.files"
          :key="file._id"
          class="rounded-md border-2 border-gray-700 px-4 py-2 flex flex-col gap-2"
        >
          <div>
            <router-link
              class="underline text-gray-300"
              :to="{
                name: 'player',
                params: {
                  projectId: transfer.projectId,
                  mediaId: file.media._id
                },
                query: {
                  version: filesToVersions[file._id]._id
                }
              }"
            >
              {{ file.media.name }}
            </router-link>
          </div>
          <div class="text-xs flex flex-col gap-1">
            <div v-if="file.uploadStatus">
              Status: {{ file.uploadStatus }}
            </div>
            <div v-if="file.uploadStatus">
              Size: {{ humanizeSize(file.size) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, watch } from "vue";
import { useRoute } from "vue-router";
import { useProjectTransferQuery, showToast, logger } from "@/app-utils";
import { unwrapSingleton, humanizeSize, type MediaVersion } from "@quickbyte/common";
import { formatDateTime } from "@/core";
import FolderPathBreadcrumbs from "@/components/FolderPathBreadcrumbs.vue";

const route = useRoute();
const transferId = computed(() => unwrapSingleton(route.params.transferId));
const projectId = computed(() => unwrapSingleton(route.params.projectId));
const query = useProjectTransferQuery(projectId, transferId);
const transfer = computed(() => query.data?.value ? query.data.value : undefined);
const loading = computed(() => query.isPending.value);


const filesToVersions = computed(() => {
  const map: Record<string, MediaVersion> = {};

  if (!transfer.value) return {};

  for (const file of transfer.value.files) {
    const media = transfer.value.targetMedia ?
      // If uploaded versions to an existing media asset
      transfer.value.targetMedia :
      // If uploaded separate media assets to a project
      file.media;

    const version = media.versions.find(v => v.fileId === file._id);
    if (!version) {
      logger?.warn(`Could not find version corresponding to file ${file._id} in media ${media._id} when viewing project transfer ${transfer.value._id}`);
      continue;
    }

    map[file._id] = version;
  }
  
  return map;
});

function getFolderLink(folderId: string) {
  return ({ name: 'project-media', params: { projectId: route.params.projectId, folderId } });
}

watch(query.error, (error) => {
  if (error) {
    logger?.error(error.message, error);
    showToast(error.message, 'error');
  }
});
</script>
<template>
</template>
<script lang="ts" setup>
import type { Activity } from "@quickbyte/common";
import { logger } from "@/app-utils";

const props = defineProps<{
  activity: Activity
}>();

logger?.warn(`Attempted to render unsupported activity type '${props.activity.type}'`);
</script>
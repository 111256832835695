<template>
  <div class="p-5 w-full sm:w-[700px] flex flex-col items-center justify-center mx-auto">
    <div v-if="loading">
      Loading recent activity...
    </div>
    <div v-else-if="!activities.length">
      No recent activity found in this project.
    </div>
    <div v-else class="w-full">
      <div class="mb-4 text-xs text-center">Here's a summary of recent activity in this project.</div>
     <ActivityList :activities="activities" />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, computed, watch } from "vue";
import { useRoute } from "vue-router";
import { unwrapSingleton } from "@quickbyte/common";
import { useProjectActivitiesQuery, showToast, logger } from "@/app-utils";
import { ActivityList } from "@/components/activity";
import { UiLayout } from "@/components/ui";

const route = useRoute();
const projectId = computed(() => unwrapSingleton(route.params.projectId));
const query = useProjectActivitiesQuery(projectId);
const activities = computed(() => query.data?.value ? query.data.value : []);
const loading = computed(() => query.isPending.value);

watch(query.error, (error) => {
  if (error) {
    logger?.error(error.message, error);
    showToast(error.message, 'error');
  }
});
</script>
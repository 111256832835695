<template>
  <UiButton primary @click="planDialog?.open()">
    {{ label || 'Get a Subscription' }}
  </UiButton>
  <SubscriptionPlansDialog ref="planDialog" :account="account" />
</template>
<script lang="ts" setup>
import { ref } from "vue";
import type { AccountWithSubscription } from "@quickbyte/common";
import { SubscriptionPlansDialog } from '@/components/subscriptions';
import { UiButton } from "@/components/ui";

defineProps<{
  label?: string;
  account: AccountWithSubscription
}>();

const planDialog = ref<typeof SubscriptionPlansDialog>();
</script>
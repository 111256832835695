import { ref, computed, readonly,  unref, type MaybeRef, type Ref } from "vue";
import { logger, windowUnloadManager, showToast, downloaderProvider, taskManager } from '@/app-utils';
import { isNetworkError, isOperationCancelledError, MIN_SIZE_FOR_DOWNLOAD_WARNING } from "@/core";
import type { ZipDownloadRequestFile } from "@/core";
import { pluralize } from "@quickbyte/common";

type Status = 'pending' | 'preparing' | 'downloading' | 'done' | 'error';

export interface StartZipDownloadArgs {
    /**
     * The preferred name of the download (without the file extension).
     * This will be used to generate the zip file name, but it can
     * be overriden by the user. Use the `zipFileName` in the result
     * to get the actual file name.
     */
    preferredName: MaybeRef<string>;
    /**
     * The files to download into the zip folder.
     */
    files: MaybeRef<ZipDownloadRequestFile[]>
}

interface ZipDownloadTrackers {
    downloadedPercentage: Ref<number>;
    status: Ref<Status>;
    error: Ref<Error|undefined>;
    totalSize: Ref<number>;
    zipFileName: Ref<string>;
}

export function useMultiFileZipDownload() {
    const downloadedPercentage = ref(0);
    const zipFileName = ref('');
    const status = ref<Status>('pending');
    const error = ref<Error | undefined>();
    const totalSize = ref(0);

    const downloadedBytes = computed(() => {
        if (totalSize.value === 0) return 0;

        const downloaded = downloadedPercentage.value * totalSize.value / 100;
        return Math.min(downloaded, totalSize.value);
    });

    function reset() {
        downloadedPercentage.value = 0;
        zipFileName.value = '';
        status.value = 'pending';
        error.value = undefined;
        totalSize.value = 0;
    };

    const startDownload = (args: StartZipDownloadArgs) => startZipDownloadInternal(args, {
        downloadedPercentage,
        zipFileName,
        status,
        error,
        totalSize
    });

    return {
        startDownload,
        reset,
        zipFileName: readonly(zipFileName),
        status: readonly(status),
        error: readonly(error),
        totalSize: readonly(totalSize),
        downloadedPercentage: readonly(downloadedPercentage),
        downloadedBytes: downloadedBytes
    };
}

async function startZipDownloadInternal(
    { preferredName, files }: StartZipDownloadArgs,
    { status, error, zipFileName, totalSize, downloadedPercentage }: ZipDownloadTrackers
) {

    status.value = 'pending';
    error.value = undefined;
    downloadedPercentage.value = 0;
    zipFileName.value = `${unref(preferredName)}.zip`;
    totalSize.value = unref(files).reduce((sizeSoFar, file) => sizeSoFar + file.size, 0);

    if (totalSize.value >= MIN_SIZE_FOR_DOWNLOAD_WARNING && !downloaderProvider.isOptimalDownloaderSupported()) {
        showToast('You may experience suboptimal download experience on this browser.', 'warning');
    }

    const downloader = downloaderProvider.getDownloader();
    const removeOnExitWarning = windowUnloadManager.warnUserOnExit();
    const task = taskManager.createTask(
        `Downloading ${unref(files).length} ${pluralize('file', unref(files).length)}`, {
        type: 'zipDownload',
        files: unref(files)
    });

    task.status = 'pending';

    try {
        await downloader.download(
            {
                name: unref(preferredName),
                files: unref(files)
            },
            zipFileName.value,
            (progress) => {
                downloadedPercentage.value = progress;
                task.progress = progress;
            },
            (userSelectedFileName) => {
                showToast(`Downloading ${unref(files).length} ${pluralize('file', unref(files).length)}`, 'info');
                zipFileName.value = userSelectedFileName;
                status.value = 'downloading';
                task.status = 'progress';
            });

        status.value = 'done';
        task.status = 'complete';
        showToast(`Download complete. The file '${zipFileName.value}' has been saved to your device.`, 'info');
    }
    catch (e: any) {
        if (isOperationCancelledError(e)) {
            task.status = 'cancelled';
            status.value = 'pending';
            return;
        }

        logger.error(e.message, e);
        task.status = 'error';
        task.error = e;
        if (isNetworkError(e)) {
            error.value = new Error('Network error occurred');
        } else {
            error.value = e as Error;
        }

        status.value = 'error';
        showToast(error.value.message, 'error');
    }
    finally {
        removeOnExitWarning();
        downloadedPercentage.value = 0;
    }
}

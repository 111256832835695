import { useQuery } from '@tanstack/vue-query'
import { trpcClient } from '../api';
import type { MaybeRef } from 'vue';
import { unref } from "vue";

export type ActivityQueryOptions = {
    from?: MaybeRef<Date|undefined>,
    to?: MaybeRef<Date|undefined>
}


export function getProjectActivitiesQueryKey(
    projectId: MaybeRef<string|undefined>,
    from: MaybeRef<Date|undefined>,
    to: MaybeRef<Date|undefined>) {
    return ['projectActivities', projectId, 'from', from, 'to', to];
}

export function useProjectActivitiesQuery(
    projectId: MaybeRef<string>,
    opts?: ActivityQueryOptions
) {
    const queryKey = getProjectActivitiesQueryKey(projectId, opts?.from, opts?.to);
    const result = useQuery({
        queryKey,
        queryFn: () => trpcClient.getProjectActivities.query({
            projectId: unref(projectId),
            from: unref(opts?.from),
            to: unref(opts?.to)
        }),
    });

    return result;
}
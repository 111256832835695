import { setLastRouteBeforeEnteringPlayerView } from "@/app-utils/player-route-tracker";
import { type NavigationGuardWithThis, type Router } from "vue-router";

/**
 * When navigating into the player view, keep track of from route so that
 * we can return to this when the close button is clicked.
 * @param to
 * @param from 
 * @returns 
 */
const trackSourceRouteBeforeEnteringPlayerView: NavigationGuardWithThis<undefined> = (to, from) => {
    if (from.name === 'player') {
        // Don't track navigations within the player view (e.g. from one media to another within the player)
        return true;
    }

    if (to.name !== 'player') {
        // Don' track navigations outside of the player
        return true;
    }

    if (!from.name) {
        // This indicates we're probably entering the site from outside Quickbyte (e.g. hard reload)
        // in this case, set the path to undefined. The player defaults to the project media view.
        setLastRouteBeforeEnteringPlayerView(undefined);
        return true;
    }

    setLastRouteBeforeEnteringPlayerView(from.fullPath);
    return true;
};

/**
 * Navigation guard hooks to execute before each route
 */
const beforeRouteHooks = [
    trackSourceRouteBeforeEnteringPlayerView
];

export function registerRouterNavigationHooks(router: Router) {
    for (const hook of beforeRouteHooks) {
        router.beforeEach(hook);
    }
}

import { useQuery } from '@tanstack/vue-query'
import { desktopTransferAppDownloader} from "@/app-utils";

export function getDeskopTransferAppDownloadInfo() {
    return ['desktopTransferAppDownloadInfo'];
}

export function useDesktopTransferAppInfoQuery() {
    const queryKey = getDeskopTransferAppDownloadInfo();
    const result = useQuery({
        queryKey,
        queryFn: () => desktopTransferAppDownloader.getAppDownloadUrls(),
        staleTime: 300_000, // 5 minutes,
        enabled: true
    });

    return result;
}

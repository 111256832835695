<template>
  <div class="flex flex-col items-center justify-center flex-1 overflow-y-auto">
    <!-- <SubscriptionCard
      @transaction="goToTransaction($event)"
    /> -->
    <SubscriptionPlans v-if="account" :account="account" />
  </div>
</template>
<script lang="ts" setup>
import { useRouter } from 'vue-router';
import { store } from "@/app-utils";
import { type VerifyTransansactionResult } from '@/core';
import { SubscriptionPlans } from '@/components/subscriptions';

const router = useRouter();

const account = store.ownedAccount;

function goToTransaction(transaction: VerifyTransansactionResult) {
  router.push({ name: 'transaction', params: { transactionId: transaction._id }})
}
</script>
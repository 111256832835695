import { readonly, ref } from "vue";


const _lastRouteBeforeEnteringPlayerView = ref<string>();

export function setLastRouteBeforeEnteringPlayerView(fullPath: string|undefined) {
    _lastRouteBeforeEnteringPlayerView.value = fullPath;
}

export const lastRouteBeforeEnteringPlayerView = readonly(_lastRouteBeforeEnteringPlayerView);

export const useLastRouteBeforeEnteringPlayerView = () => lastRouteBeforeEnteringPlayerView;

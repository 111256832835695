<template>
  <div class="h-screen overflow-y-auto bg-base-100 sm:bg-inherit">
    <div class="bg-base-100 border-b border-b-base-200 p-5 sm:rounded-t-md">
      <h2 class="text-3xl flex items-center gap-5"><Icon icon="lucide:credit-card"/> Billing</h2>
    </div>
    <template v-if="subscription">
      <div class="bg-base-100 p-5 border-b border-b-base-200 gap-3 flex flex-col">
        <div class="text-gray-500">Your current plan</div>
        <div>
          <div class="text-lg font-semibold mb-2">
            {{ subscription.plan.displayName }}
          </div>
          <div class="mb-2">
            {{ subscription.plan.currency }} {{ subscription.plan.price }}
          </div>
          <div>
            <ul class="list-disc list-inside text-sm">
              <li>Max transfer size: {{ humanizeSize(subscription.plan.maxTransferSize) }}</li>
              <li>Max storage size: {{ humanizeSize(subscription.plan.maxStorageSize) }}</li>
              <li>Max members: {{ subscription.plan.maxMembers === Infinity ? 'Unlimited' : subscription.plan.maxMembers  }} (across all projects)</li>
              <li>Max projects: {{ subscription.plan.maxProjects === Infinity ? 'Unlimited' : subscription.plan.maxProjects }}</li>
              <li v-if="subscription.plan.maxTransferValidity">
                Max transfer validity: {{ subscription.plan.maxTransferValidity }} days
              </li>
            </ul>
          </div>
        </div>
        <div v-if="subscription.renewsAt && subscription.willRenew" class="text-gray-500 text-sm">
          Your subscription will automatically renew on:
          <span class="font-semibold">
            {{ new Date(subscription.renewsAt).toLocaleDateString() }}
          </span>
        </div>
        <div v-else-if="subscription.expiresAt" class="text-gray-500 text-sm">
          Your current subscription is valid until
          <span class="font-semibold">
            {{  new Date(subscription.expiresAt).toLocaleDateString() }}
          </span>
        </div>
      </div>

      <div class="bg-base-100 p-5 sm:rounded-b-md" v-if="subscription.willRenew">
        <h3 class="text-lg mb-2">Manage card</h3>
        <div class=" text-gray-500 max-w-md">
          Click the button below to <b class="text-gray-700 font-bold">update your payment card</b> or <b class="text-gray-700">cancel</b> your subscription.
          <br>
          <br>
          <div class="text-xs">
            When you cancel your current subscription, it will continue to be active
            until {{ new Date(subscription.expiresAt!).toLocaleDateString() }}, but
            will not be renewed therafter. After the subscription expires, you will
            lose ability to transfer files until you activate another subscription.
          </div>
        </div>
        <div class="mt-3">
          <Button sm :loading="loading" @click="getManagementUrl()">Manage card</Button>
        </div>
      </div>
      <div v-else class="bg-base-100 p-5 sm:rounded-b-md">
        <div>
          This subscription will not be renewed automatically because it was cancelled or not setup for auto-renewal.
        </div>
        <div>
          After the subscription expires you may lose access to some of the features provided by your plan. 
        </div>
      </div>
      <div class="mt-5 ml-5 text-gray-500">
          <p>
            If you want to switch to a different plan before your current susbcription ends,
            contact support at <a href="mailto:support@quickbyte.io" class="underline">support@quickbyte.io</a>.
          </p>
          <p class="">
            Soon you'll be able to change plans directly from Settings.
          </p>
        </div>
    </template>
    <template v-else>
      <div class="bg-base-100 p-5 border-b border-b-base-200 gap-3 flex flex-col sm:rounded-b-md">
        <div>
          You don't have an active subscription. To purchase a subscription, click the button below.
        </div>
        <div>
          <!-- <router-link :to="{ name: 'pay' }" class="btn btn-primary">Get a Subscription</router-link> -->
           <UiButton primary @click="planDialog?.open()">
              Get a Subscription
           </UiButton>
        </div>
      </div>
    </template>
    <SubscriptionPlansDialog ref="planDialog" :account="account" />
  </div>
</template>
<script lang="ts" setup>
import { useRoute } from 'vue-router';
import { computed, ref } from 'vue';
import { Icon } from "@iconify/vue";
import { apiClient, logger, showToast, store } from '@/app-utils';
import { ensure, humanizeSize, unwrapSingleton } from '@/core';
import Button from '@/components/Button.vue';
import { SubscriptionPlansDialog } from '@/components/subscriptions';
import { UiButton } from "@/components/ui";

const route = useRoute();
const planDialog = ref<typeof SubscriptionPlansDialog>();
const loading = ref(false);

const account = computed(() => {
  const accountId = unwrapSingleton(route.params.accountId);
  return ensure(store.accounts.value.find(acc => acc._id === accountId));
});

const subscription = computed(() => account.value.subscription);

async function getManagementUrl() {
  try {
    if (!subscription.value) {
      return;
    }

    loading.value = true;
    const result = await apiClient.getSubscriptionManagementUrl(account.value._id, subscription.value._id);
    if (result.link) {
      window.open(result.link, '_blank');
    } else {
      showToast('Card management not applicable for this subscription.', 'info');
    }
  } catch (e: any) {
    showToast(e.message, 'error');
    logger.error(e);
  } finally {
    loading.value = false;
  }
}
</script>
const GITHUB_RAW_CONTENT_BASE_URL = "https://raw.githubusercontent.com";
const REPO_OWNER = 'QuickbyteHQ';

export interface DesktopTransferConfig {
    repoName: string;
}

export class DesktopTransferDownloader {
    constructor(private config: DesktopTransferConfig) {}

    private async getLatestManifest() {
        const url = `${GITHUB_RAW_CONTENT_BASE_URL}/${REPO_OWNER}/${this.config.repoName}/refs/heads/main/latest.json`;
        const res = await fetch(url);
        const manifest: Manifest = await res.json();
        return manifest;
    }

    async getAppDownloadUrls(): Promise<AppDownloadInfo> {
        const manifest = await this.getLatestManifest();
        const version = manifest.version;

        // the download urls in the manifest file contain an app tar.gz (for macOS),
        // we want to ship the disk images instead
        // we have a url of the form:
        // https://github.com/QuickbyteHQ/desktop-transfer-dev-releases/releases/download/v0.1.6-2024-10-26-10-44-50/Quickbyte.Transfer.Dev_aarch64.app.tar.gz
        const url = manifest.platforms['darwin-aarch64'].url;
        const lastSlash = url.lastIndexOf('/');
        // but we want a url of the form
        // // https://github.com/QuickbyteHQ/desktop-transfer-dev-releases/releases/download/v0.1.6-2024-10-26-10-44-50/Quickbyte.Transfer.Dev_aarch64.app.tar.gz
        const baseUrl = url.substring(0, lastSlash);
        const nameAndArch = url.substring(lastSlash + 1);
        const [name, _] = nameAndArch.split('_');

        const platforms = [
            {
                name: 'Mac (Intel)',
                extension: 'x64.dmg'
            },
            {
                name: 'Mac (Apple Silicon)',
                extension: 'aarch64.dmg'
            },
            {
                name: 'Windows',
                extension: 'x64-setup.exe'
            }
        ];

        const downloadInfo: AppDownloadInfo = {
            version,
            platforms: platforms.map(p => ({
                name: p.name,
                downloadUrl: `${baseUrl}/${name}_${version}_${p.extension}`
            }))
        };

        return downloadInfo
    }
}

export interface AppDownloadInfo {
    version: string;
    platforms: PlatformDownloaderInfo[]
}

interface PlatformDownloaderInfo {
    name: string;
    downloadUrl: string;
}

interface Manifest {
    version: string;
    notes: string;
    pub_date: string;
    platforms: {
        "darwin-aarch64": {
            signature: string;
            url: string;
        },
        "darwin-x86_64": {
            signature: string;
            url: string;
        },
        "windows-x86_64": {
            signature: string;
            url: string;
        },
    }
}
<template>
  <div class="flex-1 h-screen overflow-y-auto">
    <div class="bg-base-100 border-b border-b-base-200 p-5 sm:rounded-t-md">
      <h2 class="text-3xl flex items-center gap-5"><Icon icon="lucide:gauge"/> Account Usage</h2>
    </div>
    <div v-if="usage" class="ml-4 mt-4 flex gap-10 items-center">
      <div>
        Current subscription plan: {{ usage.plan?.displayName || 'No active subscription' }}
      </div>
      <SubscriptionPlansButton v-if="account && !account.subscription" :account="account" />
    </div>
    <div v-if="account" class="ml-4 mt-4">
      
    </div>
    <div v-if="items">
      <div v-for="item in items" :key="item.title" class="p-4 flex flex-col gap-2">
        <h3 class="text-xl">{{ item.title }}</h3>
        <div>
          {{ item.current }} of {{ item.limit }} {{ item.unit }}
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, watch } from "vue";
import { Icon } from "@iconify/vue";
import { useRoute } from "vue-router";
import { useAccountUsageQuery, store, showToast, logger } from "@/app-utils";
import { unwrapSingleton } from "@/core";
import { humanizeSize } from "@quickbyte/common"
import { SubscriptionPlansButton } from "@/components/subscriptions";

const route = useRoute();
const accountId = computed(() => unwrapSingleton(route.params.accountId));
const usageQuery = useAccountUsageQuery(accountId)
const usage = computed(() => usageQuery.data.value);
const error = computed(() => usageQuery.error.value);
const loading = computed(() => usageQuery.isPending.value);
const account = computed(() => store.accounts.value.find(acc => acc._id === accountId.value));

const items = computed(() => usage.value && [
  {
    title: 'Members',
    current: usage.value.numMembers,
    limit: finiteOrUnlimitedString(usage.value.plan?.maxMembers || 0),
    unit: 'members (across all projects)'
  },
  {
    title: 'Storage',
    current: humanizeSize(usage.value.storageSize),
    limit: humanizeSize(usage.value.plan?.maxStorageSize || 0),
    unit: ''
  },
  {
    title: 'Projects',
    current: usage.value.numProjects,
    limit: finiteOrUnlimitedString(usage.value.plan?.maxProjects || 0),
    unit: 'projects'
  }
]);

function finiteOrUnlimitedString(value: number) {
  return value === Infinity ? 'unlimited' : value.toString();
}

watch(error, () => {
  if (error.value) {
    showToast(error.value.message, 'error');
    logger.error(error.value.message, error.value);
  }
})
</script>
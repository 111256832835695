import { useQuery } from '@tanstack/vue-query'
import { trpcClient } from '../api';
import type { MaybeRef } from 'vue';
import { unref } from "vue";

export function getAccountUsageKey(accountId: MaybeRef<string>) {
    return ['accountUsage', accountId];
}


export function useAccountUsageQuery(accountId: MaybeRef<string>) {
    const queryKey = getAccountUsageKey(accountId);
    const result = useQuery({
        queryKey,
        queryFn: () => trpcClient.getAccountUsage.query({
            accountId: unref(accountId),
        }),
    });

    return result;
}
<template>
  <UiLayout>
    <ActivityItemLayout>
      <template #title>
        {{ title }}
      </template>
      <template #body>
        <div class="flex flex-col gap-1">
          <div>
            Status: {{ activity.details.transfer.status }}
          </div>
          <div>
            Number of files: {{ activity.details.transfer.numFiles }}
          </div>
          <div>
            Total size: {{ humanizeSize(activity.details.transfer.totalSize) }}
          </div>
          <div>
            <router-link
              class="underline"
              :to="{
                name: 'project-transfer',
                params: {
                  transferId: activity.details.transfer._id,
                  projectId: activity.projectId
                }
              }"
            >
              Show files
            </router-link>
          </div>
        </div>
      </template>
    </ActivityItemLayout>
  </UiLayout>
</template>
<script lang="ts" setup>
import { computed } from "vue";
import { humanizeSize, type ProjectMediaUploadedActivity } from "@quickbyte/common";
import ActivityItemLayout from "./ActivityItemLayout.vue";
import { UiLayout } from "@/components/ui";

const props = defineProps<{
  activity: ProjectMediaUploadedActivity
}>();

const title = computed(() => {
  if (props.activity.details.media) {
    if (props.activity.details.transfer.status === 'completed') {
      return `Uploaded new versions to media asset '${props.activity.details.media.name}'`;
    }

    return `Started upload of new versions to media asset '${props.activity.details.media.name}'`;
  }

  if (props.activity.details.transfer.status === 'completed') {
    return `Uploaded new media to project`;
  }

  return `Started new media upload to project`
});

const isMediaVersionsUpload = computed(() => Boolean(props.activity.details.media));
</script>
<template>
  <div>
    <!-- desktop view -->
    <div class="hidden lg:block">
      <UiLayout horizontal itemsCenter gapSm>
        <template v-if="largeScreenAncestors.length">
          <AncestorsMenu
            :ancestors="largeScreenAncestors"
            :getFolderLink="getFolderLink"
          />
        </template>
        <UiLayout
          horizontal
          itemsCenter
          gapSm
          v-for="(folder, index) in visiblePath"
          :key="folder._id"
        >
          <span :title="folder.name" class="max-w-[150px] truncate">
            <router-link
              :to="getFolderLink(folder._id)"
              class="text-gray-300 hover:text-gray-100"
              activeClass="text-white"
            >
              {{ folder.name }}
            </router-link>
          </span>
          <span
            v-if="index < visiblePath.length - 1"
            class="text-gray-500"
          >
            /
          </span>
        </UiLayout>
      </UiLayout>
    </div>
    <!-- mobile view, show only one path at most and an ellipsis to move back to the parent -->
    <div class="lg:hidden">
      <UiLayout horizontal itemsCenter gapSm>
        <UiLayout
          v-if="path.length > 1"
          horizontal
          itemsCenter
          gapSm
        >
          <AncestorsMenu
            :ancestors="ancestors"
            :getFolderLink="getFolderLink"
          />
          <span
            class="text-gray-500"
          >
            /
          </span>
        </UiLayout>
        <UiLayout
          v-if="path.length > 0"
          horizontal
          itemsCenter
          gapSm
        >
          <span :title="path[path.length - 1].name" class="max-w-[90px] sm:max-w-[150px] truncate">
            <router-link
              :to="getFolderLink(path[path.length - 1]._id)"
              class="text-gray-300 hover:text-gray-100"
              activeClass="text-white"
            >
              {{ path[path.length - 1].name }}
            </router-link>
          </span>
        </UiLayout>
      </UiLayout>
    </div>
  </div>
</template>
<script lang="ts" setup>
import type { FolderPathEntry } from "@quickbyte/common";
import { UiLayout, UiMenu, UiMenuItem } from "@/components/ui";
import { FolderIcon } from "@heroicons/vue/24/solid";
import { computed, defineComponent } from "vue";
import AncestorsMenu from "./FolderPathBreadcrumbsAncestorMenu.vue";

type GetFolderLinkFn = (folderId: string) => { name: string; params: Record<string, any> } | string;

const props = withDefaults(defineProps<{
  path: FolderPathEntry[];
  maxPathItems?: number;
  getFolderLink: GetFolderLinkFn;
}>(), {
  maxPathItems: 4
});

// used for large screen view
const firstVisibleFolderIndex = computed(() => {
  if (props.path.length <= props.maxPathItems) {
    return 0;
  }

  return props.path.length - props.maxPathItems;
});

const visiblePath = computed(() => {
  if (!firstVisibleFolderIndex.value) return props.path;
  return props.path.slice(firstVisibleFolderIndex.value);
});

const largeScreenAncestors = computed(() => {
  if (!firstVisibleFolderIndex.value) return [];

  return props.path.slice(0, firstVisibleFolderIndex.value).reverse();
});

// for small screen view
const ancestors = computed(() => {
  if (props.path.length < 2) {
    return [];
  }

  return props.path.slice(0, props.path.length - 1).reverse();
})
</script>
import { ref, computed, type Ref } from 'vue';
import PaystackPop from '@paystack/inline-js';
import type { AccountWithSubscription, User, TransactionWithSubcription } from '@quickbyte/common';
import { PAYSTACK_ONE_OFF_PAYMENT_HANDLER } from '@quickbyte/common';
import { apiClient, trpcClient, store, showToast, logger } from '@/app-utils';
import { type VerifyTransansactionResult } from '@/core';

// TODO: we hardcode this for now because
// we only have a few plans at the moment.
type PlanName = string;

type SubscriptionPaymentArgs = {
    user: User,
    account: AccountWithSubscription,
    planName: string,
    onTransaction: (verifiedTx: TransactionWithSubcription) => unknown,
    loadingTracker: Ref<boolean>,
    useMpesa?: boolean,
};

export async function payWithDefault({
    user,
    account,
    planName,
    useMpesa,
    loadingTracker: loading,
    onTransaction
}: SubscriptionPaymentArgs) {
    try {
        
        loading.value = true;
        const result = await trpcClient.initiateSubscription.mutate({ plan: planName, oneOff: useMpesa  });

        const isMpesa = result.transaction.provider === PAYSTACK_ONE_OFF_PAYMENT_HANDLER;
        
        const paystackTx = PaystackPop.setup({
            key: result.transaction.metadata.key,
            email: user.email,
            // amount is required, but will be replaced by the plan's configured amount on Paystack
            // paystack amount unit is cent, so we multiply by 100
            amount: result.plan.price * 100,
            // we don't specify a plan when using m-pesa because paystack plans don't
            // support m-pesa and the option to pay with mpesa would not be shown otherwise.
            plan: isMpesa ? "" : result.plan.providerIds.paystack,
            reference: result.transaction._id,
            callback: async (response) => {
                try {
                    loading.value = true;
                    const verifiedTx = await trpcClient.getTransaction.query(result.transaction._id);
                    onTransaction(verifiedTx);
                } catch (e: any) {
                    showToast(e.message, 'error');
                    logger.error(e);
                    loading.value = false;
                }
            },
            onClose: async () => {
                try {
                    const cancelledTx = await trpcClient.cancelTransaction.mutate(result.transaction._id);
                    showToast('Transaction cancelled.', 'info');
                } catch (e: any) {
                    showToast(e.message, 'error');
                    logger.error(e);
                }
            }
        });

        paystackTx.openIframe();
    } catch (e: any) {
        showToast(e.message, 'error');
        logger.error(e);
    } finally {
        loading.value = false;
    }
}
